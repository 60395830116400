import { React, useEffect, useState } from "react";
// import Dropdown from "react-dropdown-select";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { lighten, ListItemSecondaryAction } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import PORT from "./port";

const Addasset = () => {
  const [dropdownval, setdropdownval] = useState([]);
  const [uid, setuid] = useState("");
  const [assetbrand, setassetbrand] = useState("");
  const [assettype, setassettype] = useState("");
  const [assetdetail, setassetdetail] = useState("");
  const [assetowner, setassetowner] = useState("");
  const [assetlocation, setassetlocation] = useState("");
  const [userdata, setuserdata] = useState([]);
  const [location, setlocation] = useState([]);
  const [issubmitbuttondisabled,setissubmitbuttondisabled] =useState(false)
  useEffect(() => {
    // retrievelocalstorage();
    fetchdataadmin()
  }, []);

  // const retrievelocalstorage = () => {
  //   let data = JSON.parse(sessionStorage.getItem("data"));
  //   console.log(typeof data);
  //   setuserdata(data);
  // };
  const fetchdataadmin = () => {
    console.log('fetch data')
    return fetch(`${PORT}/admin`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setuserdata(data);
        // setisloading(false);
        // callsfilter(data);
        // console.log(data);
      })
      .catch((e) => console.log(e));
  };

  const notify = (content) => {
    toast(content);
  };

  const populatebranch = () => {
    console.log("populate branch");
    return fetch("http://localhost:3001/populatebranch", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.map((item) => {
          let username = [];
          username.push(item.username);
          // console.log(dropdownopt.length);
          // return dropdownopt.push(username), console.log(item.username);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleselect = (val) => {
    setlocation(val);
    console.log(val);
  };

  const formvalidation = () => {
    if (
      !assetowner ||
      !assetlocation ||
      !assetbrand ||
      !assettype ||
      !assetdetail
    ) {
      notify("oops! fields cannot be empty");
    } else {
      fetchdata();
    }
  };

  const fetchdata = () => {
    console.log(
      uid,
      assetbrand,
      assetowner,
      assetlocation,
      assetdetail,
      assettype
    );
    return fetch(`${PORT}/addasset`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid,
        assetbrand,
        assetowner,
        assetlocation,
        assetdetail,
        assettype,
      }),
    })
      .then((res) => {
        console.log(res.status);
        if (!res.ok) {
          setissubmitbuttondisabled(false);
          notify("asset already exists ");
          return res.json();
        } else {
          fetchdataadmin();
          setissubmitbuttondisabled(false)
          return notify("asset added successfully"), res.json();
        }
      })
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        return (
          notify("oops! something broken please try again"), console.log(e)
        );
      });
  };

  const createassetform = () => {
    return (
      <div>
      <form className="container">
        <h4>FORM</h4>

        <div ><div style={{display:'flex',justifyContent:'center'}}><h4 style={{marginRight:15,}}>{assetowner}</h4>
        <h4>{assetlocation}</h4></div></div>
        <label className="mb-0" htmlFor="dropdown">choose branch:</label>
        {/* <Dropdown
          placeholder="select a branch to create asset"
          options={userdata.map((item) => {
            return { disabled: false, value: item.username };
          })}
          onChange={(item) => {
            console.log(item);
            setdropdownval(item.value);
          }}
        /> */}
        <Dropdown className="m-2 ">
          <Dropdown.Toggle >Select your branch to create asset</Dropdown.Toggle>
            <DropdownMenu style={{maxHeight:300,overflowY:'scroll',alignSelf:"center"}} >
            {userdata.map((item) => {
              return (
                <DropdownItem
                  key={item._id}
                  eventKey={item._id}
                  onSelect={(val) => {
                    return (
                      handleselect(val),
                      setuid(item._id),
                      setassetowner(item.username)
                    );
                  }}
                >
                  {item.username}
                </DropdownItem>
              );
            })}
            </DropdownMenu>
          {/* </DropdownButton> */}
        </Dropdown>
        <Dropdown>
        <Dropdown.Toggle >Select location of branch</Dropdown.Toggle>
          {/* <DropdownButton title="Select location of branch"> */}
            <DropdownMenu style={{maxHeight:300,overflowY:'scroll',alignSelf:"center"}}>
            {userdata.map((item) => {
              if (item._id === location) {
                console.log(item);
                return item.location.map((item) => {
                  return item.split("/n").map((item, i) => {
                    return (
                      <DropdownItem
                        key={i}
                        eventKey={item}
                        onSelect={(val) => {
                          console.log(val);
                          setassetlocation(item);
                        }}
                      >
                        {item}
                      </DropdownItem>
                    );
                  });
                });
              }
            })}
          {/* </DropdownButton> */}
          </DropdownMenu>
        </Dropdown>
        <label className="mt-3">Asset brand:</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">
            Asset brand:
          </span>
          <input
            onChange={(e) => {
              setassetbrand(e.target.value);
            }}
            type="text"
            class="form-control"
            placeholder="Asset brand name"
            aria-label="company name"
            aria-describedby="basic-addon1"
          />
        </div>
        <label>Asset Type:</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">
            Asset Type:
          </span>
          <input
            onChange={(e) => {
              setassettype(e.target.value);
            }}
            type="text"
            class="form-control"
            placeholder="(printer,monitor etc)"
            aria-label="Hardware"
            aria-describedby="basic-addon1"
          />
        </div>
        <label>Asset Details: </label>{" "}
        <div class="input-group mb-1">
          <span class="input-group-text" id="basic-addon1">
            Asset Details:
          </span>
          <input
            onChange={(e) => {
              setassetdetail(e.target.value);
            }}
            type="text"
            class="form-control"
            placeholder="Enter asset serial number"
            aria-label="Serial number"
            aria-describedby="basic-addon1"
          />
        </div>
        <button 
        disabled={issubmitbuttondisabled}
          onClick={(e) => {
            e.preventDefault();
            formvalidation();
            setissubmitbuttondisabled(true)
          }}
          className="btn btn-success m-2"
        >
          Submit
        </button>
        
      </form>

<div style={{display:'flex',flexDirection:'row',flexWrap:"wrap",width:'100%',overflowY:'scroll',height:'20vh',justifyContent:'start',paddingLeft:'auto'}}>{userdata.map((item)=>{
  if (item._id === uid) {
    console.log(item.asset);
    return item.asset.map((asset)=>{
      // console.log(asset);
      if (asset.assetlocation === assetlocation) {
        return <div style={{margin:2,
        alignItems:'center',backgroundColor:'lightblue',display:'flex',flexDirection:'column',
      height:'auto',
    padding:5}}>{
        Object.entries(asset).map(([key,value],i)=>{
          console.log(key,value);
          
          return<div style={{
        alignSelf:'center',
        display:'flex',flexDirection:'row',alignContent:'center'}}><h5 style={{fontSize:14,textTransform:'capitalize',fontWeight:'bold'}}>{key}</h5><text style={{marginLeft:1,marginRight:1}}>:</text><h6 style={{fontSize:12,textTransform:'capitalize',fontWeight:'bold',alignSelf:'center'}}>{value}</h6></div>
            
        })
      }</div>
      }
      
    })
  } else {
    
  }
})}</div>

</div>
    );
  };
  return (
    <>
      {" "}
      {createassetform()}, <ToastContainer />
    </>
  );
};

export default Addasset;
