import React, { useEffect, useState } from "react";
import "./userdetails.css";
import PORT from "./port";
import { toast, ToastContainer } from "react-toastify";

const Userdetials = () => {
  const [userdata, setuserdata] = useState([]);
  const [data, setdata] = useState([]);
  const [detailsdivisopen, setdetailsdivisopen] = useState(false);
  const [usercredentialdivisopen, setusercredentialdivisopen] = useState(false);
  const [isdeletelocation, setisdeletelocation] = useState(false);
  useEffect(() => {
    // retrievelocalstorage();
    fetchdata()
  }, []);
  useEffect(()=>{
    setdatafn()
  },[userdata])

  const setdatafn= ()=>{
    console.log(data._id);
    userdata.map(item=>{
      if (item._id === data._id) {
        console.log(item);
        setdata(item)
      }
    })
  }

  const fetchdata = () => {
    console.log('fetch data')
    return fetch(`${PORT}/admin`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setuserdata(data);
        // setdatafn()
        // setisloading(false);
        // callsfilter(data);
        console.log(data);
      })
      .catch((e) => console.log(e));
  };

  // const retrievelocalstorage = () => {
  //   const data = JSON.parse(sessionStorage.getItem("data"));
  //   console.log(data);
  //   return setuserdata(data);
  // };

  const deletelocation = (uid, location) => {
    fetch(`${PORT}/deletelocation`, {
      method: 'POST',
      headers: {
        Accept: "application,json",
        "Content-type": 'application/json'
      },
      body: JSON.stringify({ uid, location })
    }).then(res => { console.log(res); if (res.ok) {
      toast.success('location deleted successfully');
      fetchdata();
      // setdatafn();
      return res.json()
    } else {
      toast.warn('oops! try again')
      return res.json()
    } }).then(data => {
      console.log(data);
    })
  }

  const detailsdiv = () => {
    console.log(data);
    return (
      <div className=" userdetail-main m-5">
            <ToastContainer />
        <div className="asset-owner rounded w-100">
          <h2>asset owner</h2>
          <h1 className="text-uppercase font-bold">{data.assetowner}</h1>
        </div>
        <div className="location w-100 rounded">
          <div> <h4>LOCATIONS</h4>
            {isdeletelocation ? <button onClick={() => { setisdeletelocation(false) }}>cancel</button> : <button onClick={() => { setisdeletelocation(true) }}>delete location</button>}
            { }
          </div>
          <div className="d-flex location-main">
            {data.location.map((item) => {
              console.log(typeof item);
              return item.split("/n").map((newitem) => {
                return (
                  <section style={{ display: 'flex', alignItems: 'center' }} className="location-main-inner rounded">
                    <h5>{newitem}</h5>
                    {isdeletelocation ? <button value={newitem} onClick={(event) => { deletelocation(data._id, event.target.value) }} style={{ display: 'flex', alignItems: 'center', backgroundColor: 'red', borderRadius: 100, border: 'none', height: 20, margin: 5 }}>x</button> : null}
                  </section>
                );
              });
            })}
          </div>
        </div>
        <div className="d-flex mt-3 h-50 ">
          <div className=" rounded text-light w-25 total-calllocks">
            <label htmlFor="calldata">Total No. of call locked</label>
            <h>{data.calldata.length}</h>
          </div>
          <div className=" calllock-history ml-2 rounded">
            <h6>recent calls</h6>
            <table>
              <thead>
                <tr>
                  <th>name</th>
                  <th>location</th>
                  <th>asset type</th>
                  {/* <th>remark</th> */}
                  <th>call status</th>
                  <th>assigned to</th>
                  <th>assigned on</th>
                </tr>
              </thead>
              <tbody>

                {data.calldata.map((item, i) => {
                  return (
                    <>
                      <tr>
                        <td>{data.assetowner}</td>
                        <td>{item.location}</td>
                        <td>{item.assettype}</td>
                        {/* <td>{item.remark}</td> */}
                        <td>{item.callstatus}</td>
                        <td>mr. {item.assignedto}</td>
                        <td>{item.assignedon}</td>
                      </tr>
                    </>
                  )
                })}

              </tbody>
            </table>
            <div className="d-flex calllock-history-heading"></div>
          </div>
        </div>
        <div className="userdetails-credentials-main rounded mt-3">
          {usercredentialdivisopen ? (
            <div>
              <h5>Username:{data.username}</h5>
              <h5>Password:{data.password}</h5>
              <div>
                <p>
                  <u>Share credentials via mail? </u>
                </p>
              </div>
            </div>
          ) : (
            <>
              <h1>USER CREDENTIALS</h1>
              <h5>
                <u
                  onClick={() => {
                    setusercredentialdivisopen(true);
                  }}
                >
                  click to reveal username and password
                </u>
              </h5>
            </>
          )}
        </div>
      </div>
    );
  };
  const branchlist = () => {
    return (
      <>
        <h1>BRANCH LIST</h1>
        <div className="table-container-main">

          {/* <table> */}

          <ul>
            <li>
              {userdata.map((item, i) => {
                return (
                  <>
                    <td>{i + 1}</td>
                    {/* <td> */}
                    <u
                      onClick={() => {
                        console.log(item);
                        console.log(userdata);
                        setdata(item);
                        setdetailsdivisopen(true);
                      }}
                    >
                      {item.assetowner}
                    </u>
                    {/* </td> */}
                  </>
                );
              })}
            </li>
          </ul>
          {/* </table> */}
        </div>
      </>
    );
  };

  return (
    <div className="container ">
      {detailsdivisopen ? detailsdiv() : branchlist()}
    </div>
  );
};

export default Userdetials;
