import React, { useEffect, useState } from "react";
import "./Hero.css";
import Createuser from "./Createuser";
import Addasset from "./Addasset";
import Add_circle_outline from "@material-ui/icons/AddCircleOutline";
import Remove_circle_outline from "@material-ui/icons/RemoveCircleOutline";
import Renderpartnerdetails from "./Renderpartner";
import { Button } from "@material-ui/core";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Calllockmanagement from "./Callsmanagement";
import Userdetials from "./Userdetails";
import Navbar from "./Navbar";
import {FaUsers} from "react-icons/fa"
import {SiBuzzfeed} from "react-icons/si"
import {MdAddToQueue} from 'react-icons/md'
import {FaUserEdit} from 'react-icons/fa'
import PORT from "./port";
import ipcllogo from '../assets/ipcl-logo.png'

const Hero = () => {
  const [fdata, setfdata] = useState([]);
  const [adassetformisopen, setaddassetformisopen] = useState(false);
  const [createloginformisopen, setcreateloginformisopen] = useState(false);
  const [buttonswitch, setbuttonswitch] = useState();
  const [removebutton, setremovebutton] = useState("none");
  const [showbutton, setshowbutton] = useState("block");
  const [renderpartnerdetail, setrenderpartnerdetail] = useState("");
  const [branchlocation, setbranchlocation] = useState("");
  const [calllockformisopen, setcalllockformisopen] = useState(false);
  const [userdetailsisopen, setuserdetailsisopen] = useState(false);

  //   const [renderform, setrenderform] = useState(createassetform());

  useEffect(() => {
    populatingPartners();
  }, []);

  // const createassetform = () => {
  //   return (
  //     <form>
  //       <h4>FORM</h4>
  //       <label htmlFor="dropdown">choose branch:</label>
  //       {console.log(dropdownopt)}
  //       <Dropdown
  //         placeholder="select a branch to create asset"
  //         options={dropdownopt.map((item) => {
  //           return [item];
  //         })}
  //         onChange={(e) => {
  //           console.log(e);
  //         }}
  //       />
  //       {/* <Dropdown placeholder="select location of branch" /> */}
  //       <label>Branch Name:</label>
  //       <div class="input-group mb-3">
  //         <span class="input-group-text" id="basic-addon1">
  //           Branch Name:
  //         </span>
  //         <input
  //           type="text"
  //           class="form-control"
  //           placeholder="Enter Branch Details"
  //           aria-label="Serial number"
  //           aria-describedby="basic-addon1"
  //         />
  //       </div>
  //       <label>Asset Type:</label>
  //       <div class="input-group mb-3">
  //         <span class="input-group-text" id="basic-addon1">
  //           Asset Details:
  //         </span>
  //         <input
  //           type="text"
  //           class="form-control"
  //           placeholder="Hardware"
  //           aria-label="Hardware"
  //           aria-describedby="basic-addon1"
  //         />
  //       </div>
  //       <label>Asset Details: </label>{" "}
  //       <div class="input-group mb-3">
  //         <span class="input-group-text" id="basic-addon1">
  //           Asset Details:
  //         </span>
  //         <input
  //           type="text"
  //           class="form-control"
  //           placeholder="Enter asset serial number"
  //           aria-label="Serial number"
  //           aria-describedby="basic-addon1"
  //         />
  //       </div>
  //       <button className="btn btn-success">Submit</button>
  //     </form>
  //   );
  // };

  const removeaddassetbtnfn = () => {
    return (
      <div>
        <button
      style={{fontSize:10}}

          // style={{ display: removebutton }}
          onClick={() => {
            //   setremovebutton("none");
            //   setshowbutton("block");
            setaddassetformisopen(false);
          }}
          className="btn btn-danger "
        >
          <MdAddToQueue fontSize="50" /> Cancel
        </button>
      </div>
    );
  };

  const showaddassetbtnfn = () => {
    return (
      <div>
        <button
          style={{ fontSize:10 }}
          onClick={() => {
            //   setremovebutton("block");
            //   setshowbutton("none");
            setaddassetformisopen(true);
            // toggleaddassetform();
            formtoggler.toggleaddassetform();
            // if (dropdownopt.length === 0) {
            // populatebranch();
            // }
          }}
          className="btn btn-primary "
        >
          {/* <FaBeer/> */}
          <MdAddToQueue fontSize="50" /> Add Asset
        </button>
      </div>
    );
  };
  const populatingPartners = () => {
    return fetch(`${PORT}/admin`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setfdata(data);
        sessionStorage.setItem("data", JSON.stringify(data));
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const togglepopup = () => {
    setcreateloginformisopen(!createloginformisopen);
  };

  // const togglecreateloginform = () => {
  //   setaddassetformisopen(!adassetformisopen);
  // };

  const showcalllockbtn = () => {
    return (
      <div>
        {" "}
        <button
      style={{fontSize:10}}

          onClick={() => {
            setcalllockformisopen(true);
            formtoggler.togglecallmanagement();
          }}
          className="btn btn-primary"
        >
          <SiBuzzfeed fontSize="50" style={{marginBottom:4}}/>calls
        </button>
      </div>
    );
  };
  const removecalllockbtn = () => {
    return (
      
      <button
      style={{fontSize:10}}

        onClick={() => {
          setcalllockformisopen(false);
        }}
        className="btn btn-danger"
      >
        <SiBuzzfeed fontSize="50" />cancel
      </button>
    );
  };

  const fetchpartnerdetail = (partner) => {
    return fetch("http://localhost:3001/partnerdetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ partner }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let name = data.name;
        console.log(data);
        setrenderpartnerdetail(data);
        togglepopup();
      });
  };

  const showcreateloginbtnfn = () => {
    return (
      <div>
        {" "}
        <button
      style={{fontSize:9}}

          onClick={() => {
            setcreateloginformisopen(!createloginformisopen);
            formtoggler.togglecreateloginform();
          }}
          className="btn btn-primary "
        >
          <FaUserEdit fontSize="50" /> Create Login
        </button>
      </div>
    );
  };

  const removecreateloginbtnfn = () => {
    return (
      <button
      style={{fontSize:10}}
        onClick={() => {
          setcreateloginformisopen(!createloginformisopen);
          formtoggler.togglecreateloginform()
        }}
        className="btn btn-danger "
      >
        <FaUserEdit fontSize="50" /> cancel
      </button>
    );
  };

  const removeuserdetailsfn = () => {
    return (
      <button
      style={{fontSize:10}}
        onClick={() => {
          setuserdetailsisopen(!userdetailsisopen);
        }}
        className="btn btn-danger "
      >
        <FaUsers fontSize="50" />cancel
      </button>
    );
  };
  const showuserdetailsfn = () => {
    return (
      <div>
        <button
      style={{fontSize:10}}

          // style={{ display: showbutton }}
          onClick={() => {
            //   setremovebutton("block");
            //   setshowbutton("none");
            setuserdetailsisopen(true);
            // toggleaddassetform();
            formtoggler.toggleuserdetails();
            // if (dropdownopt.length === 0) {
            // populatebranch();
            // }
          }}
          className="btn btn-primary "
        >
          <FaUsers fontSize="50"/> users
        </button>
      </div>
    );
  };

  class formtoggler {
    static togglecreateloginform() {
      setaddassetformisopen(false);
      setcalllockformisopen(false);
      setuserdetailsisopen(false)
    }

    static toggleaddassetform() {
      setcreateloginformisopen(false);
      setcalllockformisopen(false);
      setuserdetailsisopen(false);
    }
    static togglecallmanagement() {
      setaddassetformisopen(false);
      setcreateloginformisopen(false);
      setuserdetailsisopen(false);
    }
    static toggleuserdetails() {
      setaddassetformisopen(false);
      setcreateloginformisopen(false);
      setcalllockformisopen(false);
    }
  }

  // const formtoggler = () => {
  //   if (adassetformisopen) {
  //     setcreateloginformisopen(false);
  //   } else if (createloginformisopen) {
  //     setaddassetformisopen(false);
  //   }
  // };
  if (fdata.length === 0) {
    return (
      <>
        <div className="m-5 text-center">
          <h1>WELCOME TO IPCL ADMIN PANEL PLEASE START CREATING A USER.</h1>
        </div>
        <div className=" mt-5 ">
          <section className="container d-flex ">
            {/* {!adassetformisopen ? showaddassetbtnfn() : removeaddassetbtnfn()} */}
            {!createloginformisopen
              ? showcreateloginbtnfn()
              : removecreateloginbtnfn()}
            {/* {!calllockformisopen ? showcalllockbtn() : removecalllockbtn()} */}
            {/* {!userdetailsisopen ? showuserdetailsfn() : removeuserdetailsfn()} */}

            {/* {adassetformisopen
          ? setcreateloginformisopen(!createloginformisopen)
          : createloginformisopen
          ? setaddassetformisopen(!adassetformisopen)
          : null} */}

            {/* <Createuser name={adassetformisopen} /> */}
          </section>
          {/* <div className="container m-2">
        <h1>Partners</h1>
        <div className="d-flex">
          {fdata.map((item) => {
            return (
              <div
                onClick={(event) => {
                  let partner = event.target.innerText;
                  console.log(partner);
                  fetchpartnerdetail(partner);
                  //   setrenderpartnerdetail(partner);
                }}
                key={item._id}
                className="m-1 text-light border border-dark bg-success p-1 rounded"
              >
                {" "}
                <h5>{item.username}</h5>
              </div>
            );
          })}
          {console.log(renderpartnerdetail.active)}
        </div>
      </div> */}
          <div className="d-flex w-100 ">
            <section className=" w-100">
              {/* {adassetformisopen ? <Addasset /> : null} */}
              {createloginformisopen ? <Createuser /> : null}
              {/* {calllockformisopen ? <Calllockmanagement /> : null} */}
              {/* {userdetailsisopen ? <Userdetials /> : null} */}
            </section>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="main-container">
          {/* <section className="w-100">
            <h2>IPCL ADMIN</h2>
          </section> */}
          <div className="d-flex  ">
            <section className="navigation-main">
              {!createloginformisopen
                ? showcreateloginbtnfn()
                : removecreateloginbtnfn()}
              {!adassetformisopen ? showaddassetbtnfn() : removeaddassetbtnfn()}
              {!calllockformisopen ? showcalllockbtn() : removecalllockbtn()}
              {!userdetailsisopen ? showuserdetailsfn() : removeuserdetailsfn()}

              {/* {adassetformisopen
          ? setcreateloginformisopen(!createloginformisopen)
          : createloginformisopen
          ? setaddassetformisopen(!adassetformisopen)
          : null} */}

              {/* <Createuser name={adassetformisopen} /> */}
              <div style={{margin:1,backgroundColor:'white',
              justifyContent:'flex-end',alignSelf:'flex-end'}}><img  height="30px" width="100%" src={ipcllogo} alt="ipcl-logo" /></div>
            </section>
            {/* <div className="container m-2">
        <h1>Partners</h1>
        <div className="d-flex">
          {fdata.map((item) => {
            return (
              <div
                onClick={(event) => {
                  let partner = event.target.innerText;
                  console.log(partner);
                  fetchpartnerdetail(partner);
                  //   setrenderpartnerdetail(partner);
                }}
                key={item._id}
                className="m-1 text-light border border-dark bg-success p-1 rounded"
              >
                {" "}
                <h5>{item.username}</h5>
              </div>
            );
          })}
          {console.log(renderpartnerdetail.active)}
        </div>
      </div> */}
            <div className=" w-100 form-main">
              <section className="w-100  ">
                {createloginformisopen ? <Createuser /> : null}
                {adassetformisopen ? <Addasset /> : null}
                {calllockformisopen ? <Calllockmanagement /> : null}
                {userdetailsisopen ? <Userdetials /> : null}
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Hero;
