import React, { useEffect, useState } from "react";
import Popup from "./Popup";
import { toast, ToastContainer } from "react-toastify";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import LoadingOverlay from "react-loading-overlay";
import { Overlay } from "react-bootstrap";
import "./callmanagement.css";
import axios from "axios";
import ImageUploading from "react-images-uploading";
import { SwapVerticalCircleSharp, ToggleOnSharp } from "@material-ui/icons";
import ImageViewer from "react-simple-image-viewer";
import PORT from "./port";
// import { Button } from "bootstrap";

const Callsmanagement = () => {
  const [userdata, setuserdata] = useState([]);
  const [assigncallinput, setassigncallinput] = useState({ assignto: "" });
  const [image, setimage] = useState("");
  const [testimage, settestimage] = useState("");
  const [ispopup, setispopup] = useState(false);
  const [isimage, setisimage] = useState(false);
  const [uploadedimage, setuploadedimage] = useState({ status: false });
  const [isassigncallinputopen, setisassigncallinputopen] = useState(false);
  const [isrejectcallinputopen, setisrejectcallinputopen] = useState(false);
  const [popupcontent, setpopupcontent] = useState([]);
  const [imagecontent, setimagecontent] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [activecalldivisopen, setactivecalldivisopen] = useState(false);
  const [assignedcalldivisopen, setassignedcalldivisopen] = useState(false);
  const [closedcalldivisopen, setclosedcalldivisopen] = useState(false);
  const [rejectedcallisopen, setrejectedcallisopen] = useState(false);
  const [allcallsdivisopen, setallcallsdivisopen] = useState(false);
  const [assignedcalllockdata, setassignedcalllockdata] = useState([]);
  const [activecalllockdata, setactivecalllockdata] = useState([]);
  const [closedcalllockdata, setclosedcalllockdata] = useState([]);
  const [rejectedcalllockdata, setrejectedcalllockdata] = useState([]);
  const [allcallslockdata, setallcallslockdata] = useState([]);
  const [uid, setuid] = useState("");
  const [refid, setrefid] = useState("");
  const [images, setImages] = useState("");
  // const [images, setImages] = useState([]);
  useEffect(() => {
    // retrievelocalstorage();
    fetchdata();
  }, []);

  const notify = (content) => {
    toast(content);
  };

  useEffect(()=>{

    if (userdata.length === undefined || userdata.length === 0) {
    refresh()
    }
  },[userdata])


  const refresh = ()=>{
    setInterval(() => {
    console.log('refresh');
    fetchdata()
    }, 10000);
  }

  const handlechange = (e) => {
    setassigncallinput({ [e.target.name]: e.target.value });
    // setuploadedimage({ [e.target.name]: {}});
    // setassigncallinput({ input: e.target.value });
    // setassigncallinput({ [e.target.name]: e.target.value });
  };

  //   useEffect(() => {
  //     callsfilter();
  //   });

  // const retrievelocalstorage = async () => {
  //   let data = await JSON.parse(sessionStorage.getItem("data"));
  //   if (data) {
  //     console.log("done");
  //     setuserdata(data);
  //     setisloading(false);
  //     callsfilter(data);
  //   } else {
  //     console.log("not done");
  //   }
  // };

  const getimage = () => {
    fetch("http://localhost:3001/getimage", {
      method: "POST",
      "content-type": "application/json",
      Accept: "application/json",
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((data) => {
        console.log(data.calldata[0]);
        // settestimage(data.calldata[0].image.filename)
      });
  };
  // getimage()

  const fetchdata = () => {
    console.log('fetch data')
    return fetch(`${PORT}/admin`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setuserdata(data);
        setisloading(false);
        callsfilter(data);
        console.log(data);
      })
      .catch((e) => console.log(e));
  };

  const loadingfn = () => {
    if (isloading === true) {
      console.log("loading");
      return <img src={isloading} alt="" />;
    }
  };

  const handleclose = () => {
    return setisimage(false), setispopup(false);
  };




  const callsfilter = (data) => {

    let alltemp = []
    let activetemp = []
    let assignedtemp = []
    let closedtemp = []
    let rejectedtemp = []

    console.log(data);


    data = data.map(data => {
      if (data.calldata) {
        console.log(data);
        return { data: data.calldata, branchname: data.assetowner, uid: data._id }
      }
    })

    console.log(data);

    data.map(item => {
      if (item.data.length !== 0) {
        item.data.filter(call => {
          if (call.callstatus !== undefined) {
            let newitem = { branchname: item.branchname, item: call, uid: item.uid };
            console.log(call.callstatus);

            alltemp.push(newitem)
          }

        })

        // arr.push(rej)
      }
    })

    data.map(item => {
      if (item.length !== 0) {
        item.data.filter(call => {
          if (call.callstatus !== undefined && call.callstatus === "active") {
            let newitem = { branchname: item.branchname, item: call, uid: item.uid };
            console.log(call.callstatus);

            activetemp.push(newitem)
          }

        })
      }
    })


    data.map(item => {
      if (item.length !== 0) {
        item.data.filter(call => {
          if (call.callstatus !== undefined && call.callstatus === "assigned") {
            let newitem = { branchname: item.branchname, item: call, uid: item.uid };
            console.log(call.callstatus);

            assignedtemp.push(newitem)
          }

        })
      }
    })

    data.map(item => {
      if (item.length !== 0) {
        item.data.filter(call => {
          if (call.callstatus !== undefined && call.callstatus === "closed") {
            let newitem = { branchname: item.branchname, item: call, uid: item.uid };
            console.log(call.callstatus);

            closedtemp.push(newitem)
          }

        })
      }
    })
    data.map(item => {
      if (item.length !== 0) {
        item.data.filter(call => {
          if (call.callstatus !== undefined && call.callstatus === "rejected") {
            let newitem = { branchname: item.branchname, item: call, uid: item.uid };
            console.log(call.callstatus);

            rejectedtemp.push(newitem)
          }

        })
      }
    })

    setallcallslockdata(alltemp)
    setactivecalllockdata(activetemp)
    setassignedcalllockdata(assignedtemp)
    setclosedcalllockdata(closedtemp)
    setrejectedcalllockdata(rejectedtemp)
    console.log(activetemp);



  }




  const callsfilter1 = (data) => {
    console.log("calls filter");


    let newuserdata = data;
    let mappeddata = newuserdata.map((data) => {
      let branchname = data.username;
      // data = data.calldata



      console.log(data);




      // let allcalldatafilter = data.filter(item => {
      //   if (item.callstatus === "rejected") {
      //   let newitem = [{ branchname: data.assetowner, item, uid: data._id }];
      //   return newitem
      //   }
      // })

      // if (data.calldata !== undefined) {
      //   // setactivecalllockdata([])
      //   // setallcallslockdata([])
      //   // setassignedcalllockdata([])
      //   // setrejectedcalllockdata([])
      //   // setclosedcalllockdata([])
      //   data.calldata.filter((item) => {
      //     //  if (item.callstatus) {
      //     //   item = { branchname: data.assetowner, item, uid: data._id };
      //     //   // setassignedcalllockdata(item);
      //     //   allcallslockdata.push(item);
      //     //   return;
      //     // }
      //     if (item.callstatus) {
      //       let newitem = [{ branchname: data.assetowner, item, uid: data._id }];
      //       setallcallslockdata(newitem);
      //       // allcallslockdata.push(newitem);
      //       // console.log(item.callstatus !== "" || item.callstatus !== null);
      //       if (item.callstatus === "active") {
      //         console.log(data);
      //         item = { branchname: data.assetowner, item, uid: data._id };
      //         console.log(item);
      //         // setactivecalllockdata([]);
      //         activecalllockdata.push(item);
      //         return;
      //       } else if (item.callstatus === "assigned") {
      //         item = { branchname: data.assetowner, item, uid: data._id };
      //         // setassignedcalllockdata(item);
      //         assignedcalllockdata.push(item);
      //         return;
      //       } else if (item.callstatus === "rejected") {
      //         item = { branchname: data.assetowner, item, uid: data._id };
      //         // setassignedcalllockdata(item);
      //         rejectedcalllockdata.push(item);
      //         return;
      //       } else if (item.callstatus === "closed") {
      //         item = { branchname: data.assetowner, item, uid: data._id };
      //         // setassignedcalllockdata(item);
      //         closedcalllockdata.push(item);
      //         return;
      //       }
      //     }
      //   });
      // }
      // return allcalldatafilter
      return data.calldata
    });
  


    


    let allcalldatafilter = mappeddata.filter(item => {
      // if (item.callstatus === "rejected") {
      // let newitem = [{ branchname: data.assetowner, item, uid: data._id }];
      // return newitem
      // }

      console.log(item);
      return item
    })

    setrejectedcalllockdata(allcalldatafilter)
    console.log('call filter')
    console.log(mappeddata);
    console.log(allcalldatafilter);
    console.log(rejectedcalllockdata);
  };
  const assigncallfetch = (id) => {
    // notify("Input employee name to assign");
    console.log(Object.keys(assigncallinput) == id);
    console.log(Object.keys(assigncallinput)[0]);
    console.log(id, uid);
    console.log("uid ====>" + uid);
    console.log("refid ====>" + refid);
    console.log("assigncallinput ===>" + assigncallinput);
    console.log("key assigncallinput ===>" + Object.keys(assigncallinput)[0]);
    console.log(
      uid && refid && assigncallinput && Object.keys(assigncallinput)[0]
    );
    if (uid && refid && assigncallinput && Object.keys(assigncallinput)[0]) {
      if (Object.keys(assigncallinput)[0] == refid) {
        // setisloading(true);
        let inputvalue = Object.values(assigncallinput)[0];
        // alert("no value");

        fetch(`${PORT}/assigncall`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            value: inputvalue,
            uid: uid,
            refid: refid,
          }),
        })
          .then((res) => {
            if (res.ok) {
              setisloading(false);
              toast.success("call assigned");

              return res.json();
            } else if (res.status === 403) {
              toast.warn("call already assigned");
              return res.json();
            }
          })
          .then((data) => {
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setisloading(false);
            fetchdata();
          });
      }
    } else {
      toast.warn("please check fields and try again");
    }

    // if (!assigncallinput || !uid || !refid) {
    //   notify("Input employee name to assign");
    // } else {
    //
    // }
  };

  const closecallfetch = (id) => {
    // notify("Input employee name to assign");
    console.log("closecall" + refid);
    console.log(Object.keys(assigncallinput) == id);
    console.log(Object.values(assigncallinput));
    console.log(refid, uid);
    if (uid && refid) {
      if (refid) {
        // setisloading(true);
        let inputvalue = Object.values(assigncallinput)[0];

        const formdata = new FormData();
        console.log(images);
        // alert("no value");
        formdata.append("uid", uid);
        formdata.append("receipt", images);
        formdata.append("refid", refid);

        // axios.post('http://localhost:3001/closecall1',formdata,{ headers: { 'Content-Type': 'multipart/form-data' } }).then(res=>{
        //   console.log(res);
        // }).then((res) => {
        //       console.log(res)
        //       if (res.ok) {
        //         setisloading(false);
        //         toast.success("call closed");
        //         return res.json();
        //       } else if (res.status === 403) {
        //         toast.warn("call already closed");
        //         return res.json();
        //       }
        //     })
        //     .then((data) => {
        //       console.log(data);
        //     })
        //     .catch((e) => {
        //       console.log(e);
        //     })
        //     .finally(() => {
        //       setisloading(false);
        //     });

        fetch(`${PORT}/closecall`, {
          method: "POST",
          // headers: {
          // Accept: "application/json",
          //  "Content-Type": "multipart/form-data",
          // },
          body: formdata,
        })
          .then((res) => {
            console.log(res);
            if (res.ok) {
              setisloading(false);
              toast.success("call closed");
              setuploadedimage(false);
              setImages("");
              return res.json();
            } else if (res.status === 404) {
              toast.warn("call already closed");
              return res.json();
            }
          })
          .then((data) => {
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setisloading(false);
            fetchdata();
          });
      }
    } else {
      toast.warn("please check fields and try again");
    }

    // if (!assigncallinput || !uid || !refid) {
    //   notify("Input employee name to assign");
    // } else {
    //
    // }
  };

  const rejectcallfetch = (id) => {
    // notify("Input employee name to assign");
    console.log("closecall" + refid);
    console.log(Object.keys(assigncallinput) == id);
    console.log(Object.values(assigncallinput));
    console.log(refid, uid);
    if (uid && refid) {
      if (refid) {
        // setisloading(true);
        // let inputvalue = Object.values(assigncallinput)[0];

        // const formdata = new FormData();
        // console.log(images);
        // // alert("no value");
        // formdata.append("uid", uid);
        // formdata.append("receipt", images);
        // formdata.append("refid", refid);

        // axios.post('http://localhost:3001/closecall1',formdata,{ headers: { 'Content-Type': 'multipart/form-data' } }).then(res=>{
        //   console.log(res);
        // }).then((res) => {
        //       console.log(res)
        //       if (res.ok) {
        //         setisloading(false);
        //         toast.success("call closed");
        //         return res.json();
        //       } else if (res.status === 403) {
        //         toast.warn("call already closed");
        //         return res.json();
        //       }
        //     })
        //     .then((data) => {
        //       console.log(data);
        //     })
        //     .catch((e) => {
        //       console.log(e);
        //     })
        //     .finally(() => {
        //       setisloading(false);
        //     });

        fetch(`${PORT}/rejectcall`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid: uid, refid: refid }),
        })
          .then((res) => {
            console.log(res);
            if (res.ok) {
              setisloading(false);
              toast.success("call rejected");
              setuploadedimage(false);
              // setallcallslockdata([])
              fetchdata();
              return res.json();
            } else if (res.status === 404) {
              toast.warn("call already rejected");
              fetchdata();
              return res.json();
            } else {
              return res.json()
            }
          })
          .then((data) => {
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setisloading(false);
          });
      }
    } else {
      toast.warn("please check fields and try again");
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    // trial()
  };

  // const trial = ()=>{
  //   console.log(images)
  // }

  // const trial = ()=>{
  //   console.log(pictures)

  // }

  const activecalllockdiv = () => {
    return (
      <div>
        {/* {uploadedimage.status === true ? <div className="image-upload-main"><h4>{uploadedimage.branchname},{uploadedimage.assettype}</h4>
        <form action="">
          <input type="file" placeholder="select receipt image" onChange={(e)=>{
            setImages(e.target.files[0])
            console.log(e.target.files[0])
          }}/>
          <button onClick={(e)=>{return(
            e.preventDefault(),
            closecallfetch())}}>submit</button>
        </form>
        

        <button onClick={() => {return setuploadedimage({ status: 'false' }), setImages([]) }} style={{ position: "absolute", top: 0, border: 0, right: 40, backgroundColor: 'red', borderRadius: '40%' }}>x</button> <button onClick={() => {
          return (
            closecallfetch()
          )
        }}>submit and close call</button></div> : null} */}
       <table>
          <thead>
            <tr>
              <th></th>
              <th>Branch Name</th>
              <th>Location</th>
              <th>Asset Type</th>
              <th>call Detail</th>
              {/* <th>receipt</th> */}
              <th>image</th>
              <th>Status</th>
              <th>action</th>
              <th>Assigned to</th>
              <th>Assigned on</th>
              <th>view all details</th>
            </tr>
          </thead>
          <tbody>
            {activecalllockdata.map((call, i) => {
              console.log(call);

              //   let newcall = call.map((call) => {
              //     return call;
              //   });
              //   console.log(newcall);
              return (
                <>
                  <tr key={call.item.refid}>
                    <td>{i + 1}.</td>
                    <td>{call.branchname}</td>
                    <td>{call.item.location}</td>
                    <td>{call.item.assettype}</td>
                    <td>{call.item.remark}</td>
                    {/* <td>
                      <u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.receipt)
                          setimagecontent(
                            `http://localhost:3001/getimage/${call.item.receipt.filename}/receipt`
                          );
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          console.log(popupcontent);
                        }}
                      >
                        view receipt
                      </u>
                    </td> */}
                    <td>
                      <u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.image);
                          setimagecontent(`${call.item.image}`)
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          // );
                          console.log(popupcontent);
                        }}
                      >
                        view image
                      </u>
                    </td>
                    <td>
                      {call.item.callstatus}
                      {call.item.callstatus === "active" ? (
                        <span className="green-dot"></span>
                      ) : call.item.callstatus === !"active" ? (
                        <span className="red-dot"></span>
                      ) : null}
                    </td>
                    <td>
                      <section className="d-flex">
                        {/* <input
                          className=""
                          type="text"
                          name={call.item.refid}
                          // value={assigncallinput.textdata}
                          onChange={
                            handlechange
                            // (e) => {
                            // setassigncallinput(e.target.value);
                            // console.log(e.target.value);
                            // console.log(call.uid);
                          }
                          // }
                        /> */}
                        {/* <Imageuploader
                withIcon={true}
                buttonText='Choose images'
                onChange={(picture)=>{
                  pictures.pictures.concat(picture)
                }}
                imgExtension={'.jpg'}
                maxFileSize={5242880}
                style={{innerHeight:50}}
            /> */}
                        {Object.keys(uploadedimage) == call.item.refid ? (
                          <div className="image-upload-main1 d-flex">
                            <form action="">
                              <input
                                type="file"
                                placeholder="select receipt image"
                                onChange={(e) => {
                                  setImages(e.target.files[0]);
                                  console.log(e.target.files[0]);
                                }}
                              />
                              <button
                                onClick={(e) => {
                                  return e.preventDefault(), closecallfetch();
                                }}
                              >
                                submit
                              </button>
                            </form>
                            <button
                              onClick={() => {
                                return (
                                  setuploadedimage({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                // position: "absolute",
                                // top: 0,
                                border: 0,
                                // right: 40,
                                height: "50%",
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>{" "}
                          </div>
                        ) : Object.keys(isassigncallinputopen) ==
                          call.item.refid ? (
                          <>
                            <input
                              className=""
                              type="text"
                              name={call.item.refid}
                              // value={assigncallinput.textdata}
                              onChange={
                                handlechange
                                // (e) => {
                                // setassigncallinput(e.target.value);
                                // console.log(e.target.value);
                                // console.log(call.uid);
                              }
                            // }
                            />{" "}
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  console.log(e.target.name),
                                  // setuid(call.uid),
                                  // setrefid(e.target.name),
                                  assigncallfetch(e.target.name)
                                );
                              }}
                              className="btn btn-primary"
                            >
                              assign
                            </button>
                            <button
                              onClick={() => {
                                return (
                                  setisassigncallinputopen({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : Object.keys(isrejectcallinputopen) ==
                          call.item.refid ? (
                          <>
                            <label>this can't be undone</label>
                            <button onClick={() => {
                              rejectcallfetch()
                            }}>sure</button>
                            <button>cancel</button>
                            <button
                              onClick={() => {
                                return (
                                  setisrejectcallinputopen({ status: "false" })
                                  // setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : call.item.callstatus === "closed" ||
                          call.item.callstatus === "rejected" ? null : call.item
                            .callstatus === "active" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(e.target.name),
                                  setisassigncallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                );
                              }}
                            >
                              assign call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : call.item.callstatus === "assigned" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : null}

                        {console.log()}
                      </section>
                    </td>
                    <td>{call.item.assignedto}</td>
                    <td>{call.item.assignedon}</td>
                    <td><button onClick={() => {
                      setispopup(true)
                      setpopupcontent(<><ol style={{ textAlign: 'center' }}>{Object.entries(call.item).map(([key, value]) => {if (typeof value === 'object') {
                        return undefined
                      }else{ return <li style={{ display: 'flex', justifyContent: "center" }}><div style={{ alignSelf: 'left', textAlign: 'right', width: "50%", marginRight: 20 }}>{key}</div>:<div style={{ alignSelf: 'right', textAlign: 'left', width: "50%", marginLeft: 20 }}>{value}</div></li>} })}</ol></>)
                    }}>view details</button></td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const allcallsdiv = () => {
    return (
      <div>
        {/* {uploadedimage.status === true ? <div className="image-upload-main"><h4>{uploadedimage.branchname},{uploadedimage.assettype}</h4>
        <form action="">
          <input type="file" placeholder="select receipt image" onChange={(e)=>{
            setImages(e.target.files[0])
            console.log(e.target.files[0])
          }}/>
          <button onClick={(e)=>{return(
            e.preventDefault(),
            closecallfetch())}}>submit</button>
        </form>
        

        <button onClick={() => {return setuploadedimage({ status: 'false' }), setImages([]) }} style={{ position: "absolute", top: 0, border: 0, right: 40, backgroundColor: 'red', borderRadius: '40%' }}>x</button> <button onClick={() => {
          return (
            closecallfetch()
          )
        }}>submit and close call</button></div> : null} */}
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Branch Name</th>
              <th>Location</th>
              <th>Asset Type</th>
              <th>call Detail</th>
              <th>view receipt</th>
              <th>image</th>
              <th>Status</th>
              <th>action</th>
              <th>Assigned to</th>
              <th>Assigned on</th>
              <th>view all details</th>
            </tr>
          </thead>
          <tbody>
            {allcallslockdata.map((call, i) => {
              console.log(call);

              //   let newcall = call.map((call) => {
              //     return call;
              //   });
              //   console.log(newcall);
              return (
                <>
                  <tr key={call.item.refid}>
                    <td>{i + 1}.</td>
                    <td>{call.branchname}</td>
                    <td>{call.item.location}</td>
                    <td>{call.item.assettype}</td>
                    <td>{call.item.remark}</td>
                    <td>
                      {call.item.callstatus === "closed"?<u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.receipt)
                          setimagecontent(`${call.item.receipt}`)
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          console.log(popupcontent);
                        }}
                      >
                        view receipt
                      </u>:null}
                      
                    </td>
                    <td>
                      <u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.image);
                          setimagecontent(`${call.item.image}`)
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          // );
                          console.log(popupcontent);
                        }}
                      >
                        view image
                      </u>
                    </td>
                    <td>
                      {call.item.callstatus}
                      {call.item.callstatus === "active" ? (
                        <span className="green-dot"></span>
                      ) : call.item.callstatus === !"active" ? (
                        <span className="red-dot"></span>
                      ) : null}
                    </td>
                    <td>
                      <section className="d-flex">
                        {/* <input
                          className=""
                          type="text"
                          name={call.item.refid}
                          // value={assigncallinput.textdata}
                          onChange={
                            handlechange
                            // (e) => {
                            // setassigncallinput(e.target.value);
                            // console.log(e.target.value);
                            // console.log(call.uid);
                          }
                          // }
                        /> */}
                        {/* <Imageuploader
                withIcon={true}
                buttonText='Choose images'
                onChange={(picture)=>{
                  pictures.pictures.concat(picture)
                }}
                imgExtension={'.jpg'}
                maxFileSize={5242880}
                style={{innerHeight:50}}
            /> */}
                        {Object.keys(uploadedimage) == call.item.refid ? (
                          <div className="image-upload-main1 d-flex">
                            <form action="">
                              <input
                                type="file"
                                placeholder="select receipt image"
                                onChange={(e) => {
                                  setImages(e.target.files[0]);
                                  console.log(e.target.files[0]);
                                }}
                              />
                              <button
                                onClick={(e) => {
                                  return e.preventDefault(), closecallfetch();
                                }}
                              >
                                submit
                              </button>
                            </form>
                            <button
                              onClick={() => {
                                return (
                                  setuploadedimage({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                // position: "absolute",
                                // top: 0,
                                border: 0,
                                // right: 40,
                                height: "50%",
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>{" "}
                          </div>
                        ) : Object.keys(isassigncallinputopen) ==
                          call.item.refid ? (
                          <>
                            <input
                              className=""
                              type="text"
                              name={call.item.refid}
                              // value={assigncallinput.textdata}
                              onChange={
                                handlechange
                                // (e) => {
                                // setassigncallinput(e.target.value);
                                // console.log(e.target.value);
                                // console.log(call.uid);
                              }
                            // }
                            />{" "}
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  console.log(e.target.name),
                                  // setuid(call.uid),
                                  // setrefid(e.target.name),
                                  assigncallfetch(e.target.name)
                                );
                              }}
                              className="btn btn-primary"
                            >
                              assign
                            </button>
                            <button
                              onClick={() => {
                                return (
                                  setisassigncallinputopen({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : Object.keys(isrejectcallinputopen) ==
                          call.item.refid ? (
                          <>
                            <label>this can't be undone</label>
                            <button onClick={() => {
                              rejectcallfetch()
                            }}>sure</button>
                            <button>cancel</button>
                            <button
                              onClick={() => {
                                return (
                                  setisrejectcallinputopen({ status: "false" })
                                  // setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : call.item.callstatus === "closed" ||
                          call.item.callstatus === "rejected" ? null : call.item
                            .callstatus === "active" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(e.target.name),
                                  setisassigncallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                );
                              }}
                            >
                              assign call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : call.item.callstatus === "assigned" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : null}

                        {console.log()}
                      </section>
                    </td>
                    <td>{call.item.assignedto}</td>
                    <td>{call.item.assignedon}</td>
                    <td><button onClick={() => {
                      setispopup(true)
                      setpopupcontent(<><ol style={{ textAlign: 'center' }}>{Object.entries(call.item).map(([key, value]) => {if (typeof value === "object") {
                        return undefined
                      }else{ return <li style={{ display: 'flex', justifyContent: "center" }}><div style={{ alignSelf: 'left', textAlign: 'right', width: "50%", marginRight: 20 }}>{key}</div>:<div style={{ alignSelf: 'right', textAlign: 'left', width: "50%", marginLeft: 20 }}>{value}</div></li>} })}</ol></>)
                    }}>view details</button></td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const rejectedcalldiv = () => {
    return (
      <div>
        {/* {uploadedimage.status === true ? <div className="image-upload-main"><h4>{uploadedimage.branchname},{uploadedimage.assettype}</h4>
        <form action="">
          <input type="file" placeholder="select receipt image" onChange={(e)=>{
            setImages(e.target.files[0])
            console.log(e.target.files[0])
          }}/>
          <button onClick={(e)=>{return(
            e.preventDefault(),
            closecallfetch())}}>submit</button>
        </form>
        

        <button onClick={() => {return setuploadedimage({ status: 'false' }), setImages([]) }} style={{ position: "absolute", top: 0, border: 0, right: 40, backgroundColor: 'red', borderRadius: '40%' }}>x</button> <button onClick={() => {
          return (
            closecallfetch()
          )
        }}>submit and close call</button></div> : null} */}
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Branch Name</th>
              <th>Location</th>
              <th>Asset Type</th>
              <th>call Detail</th>
              {/* <th>receipt</th> */}
              <th>image</th>
              <th>Status</th>
              <th>action</th>
              <th>Assigned to</th>
              <th>Assigned on</th>
              <th>view all details</th>
            </tr>
          </thead>
          <tbody>
            {rejectedcalllockdata.map((call, i) => {
              console.log(call);

              //   let newcall = call.map((call) => {
              //     return call;
              //   });
              //   console.log(newcall);
              return (
                <>
                  <tr key={call.item.refid}>
                    <td>{i + 1}.</td>
                    <td>{call.branchname}</td>
                    <td>{call.item.location}</td>
                    <td>{call.item.assettype}</td>
                    <td>{call.item.remark}</td>
                    {/* <td>
                      <u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.receipt)
                          setimagecontent(
                            `http://localhost:3001/getimage/${call.item.receipt.filename}/receipt`
                          );
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          console.log(popupcontent);
                        }}
                      >
                        view receipt
                      </u>
                    </td> */}
                    <td>
                      <u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.image);
                          setimagecontent(`${call.item.image}`)
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          // );
                          console.log(popupcontent);
                        }}
                      >
                        view image
                      </u>
                    </td>
                    <td>
                      {call.item.callstatus}
                      {call.item.callstatus === "active" ? (
                        <span className="green-dot"></span>
                      ) : call.item.callstatus === !"active" ? (
                        <span className="red-dot"></span>
                      ) : null}
                    </td>
                    <td>
                      <section className="d-flex">
                        {/* <input
                          className=""
                          type="text"
                          name={call.item.refid}
                          // value={assigncallinput.textdata}
                          onChange={
                            handlechange
                            // (e) => {
                            // setassigncallinput(e.target.value);
                            // console.log(e.target.value);
                            // console.log(call.uid);
                          }
                          // }
                        /> */}
                        {/* <Imageuploader
                withIcon={true}
                buttonText='Choose images'
                onChange={(picture)=>{
                  pictures.pictures.concat(picture)
                }}
                imgExtension={'.jpg'}
                maxFileSize={5242880}
                style={{innerHeight:50}}
            /> */}
                        {Object.keys(uploadedimage) == call.item.refid ? (
                          <div className="image-upload-main1 d-flex">
                            <form action="">
                              <input
                                type="file"
                                placeholder="select receipt image"
                                onChange={(e) => {
                                  setImages(e.target.files[0]);
                                  console.log(e.target.files[0]);
                                }}
                              />
                              <button
                                onClick={(e) => {
                                  return e.preventDefault(), closecallfetch();
                                }}
                              >
                                submit
                              </button>
                            </form>
                            <button
                              onClick={() => {
                                return (
                                  setuploadedimage({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                // position: "absolute",
                                // top: 0,
                                border: 0,
                                // right: 40,
                                height: "50%",
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>{" "}
                          </div>
                        ) : Object.keys(isassigncallinputopen) ==
                          call.item.refid ? (
                          <>
                            <input
                              className=""
                              type="text"
                              name={call.item.refid}
                              // value={assigncallinput.textdata}
                              onChange={
                                handlechange
                                // (e) => {
                                // setassigncallinput(e.target.value);
                                // console.log(e.target.value);
                                // console.log(call.uid);
                              }
                            // }
                            />{" "}
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  console.log(e.target.name),
                                  // setuid(call.uid),
                                  // setrefid(e.target.name),
                                  assigncallfetch(e.target.name)
                                );
                              }}
                              className="btn btn-primary"
                            >
                              assign
                            </button>
                            <button
                              onClick={() => {
                                return (
                                  setisassigncallinputopen({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : Object.keys(isrejectcallinputopen) ==
                          call.item.refid ? (
                          <>
                            <label>this can't be undone</label>
                            <button onClick={() => {
                              rejectcallfetch()
                            }}>sure</button>
                            <button>cancel</button>
                            <button
                              onClick={() => {
                                return (
                                  setisrejectcallinputopen({ status: "false" })
                                  // setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : call.item.callstatus === "closed" ||
                          call.item.callstatus === "rejected" ? null : call.item
                            .callstatus === "active" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(e.target.name),
                                  setisassigncallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                );
                              }}
                            >
                              assign call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : call.item.callstatus === "assigned" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : null}

                        {console.log()}
                      </section>
                    </td>
                    <td>{call.item.assignedto}</td>
                    <td>{call.item.assignedon}</td>
                    <td><button onClick={() => {
                      setispopup(true)
                      setpopupcontent(<><ol style={{ textAlign: 'center' }}>{Object.entries(call.item).map(([key, value]) => {if (typeof value === 'object') {
                        return undefined
                      }else{ return <li style={{ display: 'flex', justifyContent: "center" }}><div style={{ alignSelf: 'left', textAlign: 'right', width: "50%", marginRight: 20 }}>{key}</div>:<div style={{ alignSelf: 'right', textAlign: 'left', width: "50%", marginLeft: 20 }}>{value}</div></li>} })}</ol></>)
                    }}>view details</button></td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const closedcalldiv = () => {
    return (
      <div>
        {/* {uploadedimage.status === true ? <div className="image-upload-main"><h4>{uploadedimage.branchname},{uploadedimage.assettype}</h4>
        <form action="">
          <input type="file" placeholder="select receipt image" onChange={(e)=>{
            setImages(e.target.files[0])
            console.log(e.target.files[0])
          }}/>
          <button onClick={(e)=>{return(
            e.preventDefault(),
            closecallfetch())}}>submit</button>
        </form>
        

        <button onClick={() => {return setuploadedimage({ status: 'false' }), setImages([]) }} style={{ position: "absolute", top: 0, border: 0, right: 40, backgroundColor: 'red', borderRadius: '40%' }}>x</button> <button onClick={() => {
          return (
            closecallfetch()
          )
        }}>submit and close call</button></div> : null} */}
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Branch Name</th>
              <th>Location</th>
              <th>Asset Type</th>
              <th>call Detail</th>
              <th>receipt</th>
              <th>image</th>
              <th>Status</th>
              <th>action</th>
              <th>Assigned to</th>
              <th>Assigned on</th>
              <th>view all details</th>
            </tr>
          </thead>
          <tbody>
            {closedcalllockdata.map((call, i) => {
              console.log(call);

              //   let newcall = call.map((call) => {
              //     return call;
              //   });
              //   console.log(newcall);
              return (
                <>
                  <tr key={call.item.refid}>
                    <td>{i + 1}.</td>
                    <td>{call.branchname}</td>
                    <td>{call.item.location}</td>
                    <td>{call.item.assettype}</td>
                    <td>{call.item.remark}</td>
                    <td>
                      <u
                        onClick={() => {
                          // console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.receipt)
                          setimagecontent(`${call.item.receipt}`)
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          console.log(popupcontent);
                        }}
                      >
                        view receipt
                      </u>
                    </td>
                    <td>
                      <u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.image);
                          // setimagecontent(
                          //   `${PORT}/getimage/${call.item.image.filename}/upload`
                          // );
                          setimagecontent(
                            
                          );
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          // );
                          console.log(popupcontent);
                        }}
                      >
                        view image
                      </u>
                    </td>
                    <td>
                      {call.item.callstatus}
                      {call.item.callstatus === "active" ? (
                        <span className="green-dot"></span>
                      ) : call.item.callstatus === !"active" ? (
                        <span className="red-dot"></span>
                      ) : null}
                    </td>
                    <td>
                      <section className="d-flex">
                        {/* <input
                          className=""
                          type="text"
                          name={call.item.refid}
                          // value={assigncallinput.textdata}
                          onChange={
                            handlechange
                            // (e) => {
                            // setassigncallinput(e.target.value);
                            // console.log(e.target.value);
                            // console.log(call.uid);
                          }
                          // }
                        /> */}
                        {/* <Imageuploader
                withIcon={true}
                buttonText='Choose images'
                onChange={(picture)=>{
                  pictures.pictures.concat(picture)
                }}
                imgExtension={'.jpg'}
                maxFileSize={5242880}
                style={{innerHeight:50}}
            /> */}
                        {Object.keys(uploadedimage) == call.item.refid ? (
                          <div className="image-upload-main1 d-flex">
                            <form action="">
                              <input
                                type="file"
                                placeholder="select receipt image"
                                onChange={(e) => {
                                  setImages(e.target.files[0]);
                                  console.log(e.target.files[0]);
                                }}
                              />
                              <button
                                onClick={(e) => {
                                  return e.preventDefault(), closecallfetch();
                                }}
                              >
                                submit
                              </button>
                            </form>
                            <button
                              onClick={() => {
                                return (
                                  setuploadedimage({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                // position: "absolute",
                                // top: 0,
                                border: 0,
                                // right: 40,
                                height: "50%",
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>{" "}
                          </div>
                        ) : Object.keys(isassigncallinputopen) ==
                          call.item.refid ? (
                          <>
                            <input
                              className=""
                              type="text"
                              name={call.item.refid}
                              // value={assigncallinput.textdata}
                              onChange={
                                handlechange
                                // (e) => {
                                // setassigncallinput(e.target.value);
                                // console.log(e.target.value);
                                // console.log(call.uid);
                              }
                            // }
                            />{" "}
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  console.log(e.target.name),
                                  // setuid(call.uid),
                                  // setrefid(e.target.name),
                                  assigncallfetch(e.target.name)
                                );
                              }}
                              className="btn btn-primary"
                            >
                              assign
                            </button>
                            <button
                              onClick={() => {
                                return (
                                  setisassigncallinputopen({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : Object.keys(isrejectcallinputopen) ==
                          call.item.refid ? (
                          <>
                            <label>this can't be undone</label>
                            <button onClick={() => {
                              rejectcallfetch()
                            }}>sure</button>
                            <button>cancel</button>
                            <button
                              onClick={() => {
                                return (
                                  setisrejectcallinputopen({ status: "false" })
                                  // setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : call.item.callstatus === "closed" ||
                          call.item.callstatus === "rejected" ? null : call.item
                            .callstatus === "active" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(e.target.name),
                                  setisassigncallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                );
                              }}
                            >
                              assign call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : call.item.callstatus === "assigned" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : null}

                        {console.log()}
                      </section>
                    </td>
                    <td>{call.item.assignedto}</td>
                    <td>{call.item.assignedon}</td>
                    <td><button onClick={() => {
                      setispopup(true)
                      setpopupcontent(<><ol style={{ textAlign: 'center' }}>{Object.entries(call.item).map(([key, value]) => {if (typeof value === 'object') {
                        return undefined
                      }else{ return <li style={{ display: 'flex', justifyContent: "center" }}><div style={{ alignSelf: 'left', textAlign: 'right', width: "50%", marginRight: 20 }}>{key}</div>:<div style={{ alignSelf: 'right', textAlign: 'left', width: "50%", marginLeft: 20 }}>{value}</div></li>} })}</ol></>)
                    }}>view details</button></td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  const assignedcalllockdiv = () => {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Branch Name</th>
              <th>Location</th>
              <th>Asset Type</th>
              <th>call Detail</th>
              {/* <th>receipt</th> */}
              <th>image</th>
              <th>Status</th>
              <th>action</th>
              <th>Assigned to</th>
              <th>Assigned on</th>
              <th>view all details</th>
            </tr>
          </thead>
          <tbody>
            {assignedcalllockdata.map((call, i) => {
              console.log(call);

              //   let newcall = call.map((call) => {
              //     return call;
              //   });
              //   console.log(newcall);
              return (
                <>
                  <tr key={call.item.refid}>
                    <td>{i + 1}.</td>
                    <td>{call.branchname}</td>
                    <td>{call.item.location}</td>
                    <td>{call.item.assettype}</td>
                    <td>{call.item.remark}</td>
                    {/* <td>
                      <u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.receipt)
                          setimagecontent(
                            `http://localhost:3001/getimage/${call.item.receipt.filename}/receipt`
                          );
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          console.log(popupcontent);
                        }}
                      >
                        view receipt
                      </u>
                    </td> */}
                    <td>
                      <u
                        onClick={() => {
                          console.log(call.item.image.filename);
                          setisimage(true);
                          setimage(call.item.image);
                          setimagecontent(`${call.item.image}`)
                          // <img
                          //   className="popup-image"
                          //   width="50%"

                          // />
                          // );
                          console.log(popupcontent);
                        }}
                      >
                        view image
                      </u>
                    </td>
                    <td>
                      {call.item.callstatus}
                      {call.item.callstatus === "active" ? (
                        <span className="green-dot"></span>
                      ) : call.item.callstatus === !"active" ? (
                        <span className="red-dot"></span>
                      ) : null}
                    </td>
                    <td>
                      <section className="d-flex">
                        {/* <input
                          className=""
                          type="text"
                          name={call.item.refid}
                          // value={assigncallinput.textdata}
                          onChange={
                            handlechange
                            // (e) => {
                            // setassigncallinput(e.target.value);
                            // console.log(e.target.value);
                            // console.log(call.uid);
                          }
                          // }
                        /> */}
                        {/* <Imageuploader
                withIcon={true}
                buttonText='Choose images'
                onChange={(picture)=>{
                  pictures.pictures.concat(picture)
                }}
                imgExtension={'.jpg'}
                maxFileSize={5242880}
                style={{innerHeight:50}}
            /> */}
                        {Object.keys(uploadedimage) == call.item.refid ? (
                          <div className="image-upload-main1 d-flex">
                            <form action="">
                              <input
                                type="file"
                                placeholder="select receipt image"
                                onChange={(e) => {
                                  setImages(e.target.files[0]);
                                  console.log(e.target.files[0]);
                                }}
                              />
                              <button
                                onClick={(e) => {
                                  return e.preventDefault(), closecallfetch();
                                }}
                              >
                                submit
                              </button>
                            </form>
                            <button
                              onClick={() => {
                                return (
                                  setuploadedimage({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                // position: "absolute",
                                // top: 0,
                                border: 0,
                                // right: 40,
                                height: "50%",
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>{" "}
                          </div>
                        ) : Object.keys(isassigncallinputopen) ==
                          call.item.refid ? (
                          <>
                            <input
                              className=""
                              type="text"
                              name={call.item.refid}
                              // value={assigncallinput.textdata}
                              onChange={
                                handlechange
                                // (e) => {
                                // setassigncallinput(e.target.value);
                                // console.log(e.target.value);
                                // console.log(call.uid);
                              }
                            // }
                            />{" "}
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  console.log(e.target.name),
                                  // setuid(call.uid),
                                  // setrefid(e.target.name),
                                  assigncallfetch(e.target.name)
                                );
                              }}
                              className="btn btn-primary"
                            >
                              assign
                            </button>
                            <button
                              onClick={() => {
                                return (
                                  setisassigncallinputopen({ status: "false" }),
                                  setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : Object.keys(isrejectcallinputopen) ==
                          call.item.refid ? (
                          <>
                            <label>this can't be undone</label>
                            <button onClick={() => {
                              rejectcallfetch()
                            }}>sure</button>
                            <button>cancel</button>
                            <button
                              onClick={() => {
                                return (
                                  setisrejectcallinputopen({ status: "false" })
                                  // setImages([])
                                );
                              }}
                              styleq={{
                                position: "absolute",
                                top: 0,
                                border: 0,
                                right: 40,
                                backgroundColor: "red",
                                borderRadius: "40%",
                              }}
                            >
                              x
                            </button>
                          </>
                        ) : call.item.callstatus === "closed" ||
                          call.item.callstatus === "rejected" ? null : call.item
                            .callstatus === "active" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(e.target.name),
                                  setisassigncallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                );
                              }}
                            >
                              assign call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : call.item.callstatus === "assigned" ? (
                          <div>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                console.log(call.uid);
                                return (
                                  // setisimage(true)
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  // assigncallfetch(e.target.name)
                                  setuploadedimage({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  }),
                                  // setuploadedimage({ status: true, branchname: call.branchname, assettype: call.item.assettype })
                                  console.log(
                                    uploadedimage,
                                    Object.keys(uploadedimage)
                                  )
                                );
                              }}
                            >
                              close call
                            </button>
                            <button
                              name={call.item.refid}
                              onClick={(e) => {
                                return (
                                  setuid(call.uid),
                                  setrefid(call.item.refid),
                                  setisrejectcallinputopen({
                                    [e.target.name]: {
                                      status: true,
                                      branchname: call.branchname,
                                      assettype: call.item.assettype,
                                    },
                                  })
                                  // rejectcallfetch()
                                );
                              }}
                              className=" "
                            >
                              reject call
                            </button>
                          </div>
                        ) : null}

                        {console.log()}
                      </section>
                    </td>
                    <td>{call.item.assignedto}</td>
                    <td>{call.item.assignedon}</td>
                    <td><button onClick={() => {
                      setispopup(true)
                      setpopupcontent(<><ol style={{ textAlign: 'center' }}>{Object.entries(call.item).map(([key, value]) => {if (typeof value === 'object') {
                        return undefined
                      }else{ return <li style={{ display: 'flex', justifyContent: "center" }}><div style={{ alignSelf: 'left', textAlign: 'right', width: "50%", marginRight: 20 }}>{key}</div>:<div style={{ alignSelf: 'right', textAlign: 'left', width: "50%", marginLeft: 20 }}>{value}</div></li>} })}</ol></>)
                    }}>view details</button></td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  class togglesection {
    static toggleactivecalldiv = () => {
      setassignedcalldivisopen(false);
      setallcallsdivisopen(false);
      setrejectedcallisopen(false);
      setclosedcalldivisopen(false);
    };
    static toggleassignedcalldiv = () => {
      setactivecalldivisopen(false);
      setallcallsdivisopen(false);
      setrejectedcallisopen(false);
      setclosedcalldivisopen(false);
    };
    static toggleallcallsdiv = () => {
      setactivecalldivisopen(false);
      setassignedcalldivisopen(false);
      setrejectedcallisopen(false);
      setclosedcalldivisopen(false);
    };
    static toggleclosedcalldiv = () => {
      setactivecalldivisopen(false);
      setallcallsdivisopen(false);
      setrejectedcallisopen(false);
      setassignedcalldivisopen(false);
    };
    static togglerejectedcalldiv = () => {
      setactivecalldivisopen(false);
      setallcallsdivisopen(false);
      setclosedcalldivisopen(false);
      setassignedcalldivisopen(false);
    };
  }
  class btnfunction {
    static showallcallsfn = () => {
      return (
        <button
          onClick={() => {
            setallcallsdivisopen(true);
            togglesection.toggleallcallsdiv();
          }}
          className="btn btn-primary m-auto"
        >
          All Calls
        </button>
      );
    };

    static showclosedcallbtnfn = () => {
      return (
        <button
          onClick={() => {
            setclosedcalldivisopen(true);
            togglesection.toggleclosedcalldiv();
          }}
          className="btn btn-primary m-auto"
        >
          Closed Calls
        </button>
      );
    };

    static showrejectedcallbtnfn = () => {
      return (
        <button
          onClick={() => {
            setrejectedcallisopen(true);
            togglesection.togglerejectedcalldiv();
          }}
          className="btn btn-primary m-auto"
        >
          Rejected Calls
        </button>
      );
    };

    static showrecentcallbtnfn = () => {
      return (
        <button
          onClick={() => {
            setactivecalldivisopen(true);
            togglesection.toggleactivecalldiv();
          }}
          className="btn btn-primary m-auto"
        >
          Active Calls
        </button>
      );
    };
    static showpendingcallbtnfn = () => {
      return (
        <button
          onClick={() => {
            setassignedcalldivisopen(true);
            togglesection.toggleassignedcalldiv();
          }}
          className="btn btn-primary m-auto"
        >
          Assigned calls
        </button>
      );
    };
  }

  if (!userdata.length) {
    return (
      <ClipLoader
        css={css`
        display:"block",
          top: 50%;
          position:"absolute"
        `}
      />
    );
  } else {
    return (
      <>
        {/* <img
          src={"http://localhost:3001/getimage/" + testimage}
          alt="unsplash"
          srcset=""
        /> */}
        <div >
        <LoadingOverlay
        className='loading_overlay_content'
          styles={Overlay}
          spinner={<ClipLoader />}
          active={isloading}
        >
          <div className=" mt-2 mb-2">
            {/* {loadingfn()} */}
            <ToastContainer />
            {btnfunction.showallcallsfn()}
            {btnfunction.showrecentcallbtnfn()}
            {btnfunction.showpendingcallbtnfn()}
            {btnfunction.showclosedcallbtnfn()}
            {btnfunction.showrejectedcallbtnfn()}
          </div>
          <div>
            {ispopup ? (
              <Popup content={popupcontent} handleclose={handleclose} />) : null}
            {isimage ? (
              // <Popup content={popupcontent} handleclose={handleclose} />
              <ImageViewer src={[imagecontent]} onClose={handleclose} />
            ) : null}
            <div className="main-container-callmanagement ">
              <div className="container-fluid text-center">
                {allcallsdivisopen ? allcallsdiv() : null}
                {activecalldivisopen ? activecalllockdiv() : null}
                {assignedcalldivisopen ? assignedcalllockdiv() : null}
                {closedcalldivisopen ? closedcalldiv() : null}
                {rejectedcallisopen ? rejectedcalldiv() : null}
              </div>
            </div>
          </div>
        </LoadingOverlay>
        </div>
      </>
    );
  }
};

export default Callsmanagement;
