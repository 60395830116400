import logo from "./logo.svg";
import "./App.css";
import Navbar from "./component/Navbar";
import Hero from "./component/Hero";
import {Online,Offline} from 'react-detect-offline'

function App() {
  return (
    <>
      {/* <Navbar /> */}
      <Online>
      <Hero />
      </Online>
      <Offline>internet not connected</Offline>
    </>
  );
}

export default App;
