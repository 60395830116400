import { React ,useState} from "react";
import "./Popup.css";

const Popup = ({ content, handleclose }) => {
{    console.log(content);
  }
  return (
    <div className="popup-box">
      <div className="container popupmain">
        <div className="image-container">
        {content}
        </div>
        <span onClick={handleclose} className="close-icon">
          x
        </span>
      </div>
    </div>
  );
};

export default Popup;
