import React, { useEffect, useState } from "react";
import Add_circle_outline from "@material-ui/icons/AddCircleOutline";
import Remove_circle_outline from "@material-ui/icons/RemoveCircleOutline";
import loadinggif from "../assets/loading.gif";
import Dropdown from "react-bootstrap/esm/Dropdown";
import DropdownButton from "react-bootstrap/esm/DropdownButton";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { useRadioGroup } from "@material-ui/core";
import "./createuser.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PORT from "./port";

const Createuser = (props) => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [location, setlocation] = useState("");
  const [assetowner, setassetowner] = useState("");
  const [
    selectedbranchaddnewlocation,
    setselectedbranchaddnewlocation,
  ] = useState("");
  const [showform, setshowform] = useState(false);
  const [isbuttondisabled, setisbuttondisabled] = useState(false);
  const [isaddnewlocationbtndisabled, setisaddnewlocationbtndisabled] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const [addlocationformisopen, setaddlocationformisopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [addnewlocation, setaddnewlocation] = useState("");

  useEffect(() => {
    // formtoggler();
    retrievelocalstorage();
  }, []);

  const formtoggler = () => {
    return props.name ? setshowform(false) : null;
  };

  const fetchdata = () => {
    return fetch(`${PORT}/admin`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return (
          console.log(data),
          sessionStorage.setItem("data", JSON.stringify(data)),
          retrievelocalstorage()
        );
      });
  };

  const retrievelocalstorage = () => {
    let data = JSON.parse(sessionStorage.getItem("data"));
    setuserdata(data);
    //   data.map((item) => {
    //     console.log(item.username);
    //   });
  };

  const formvalidation = () => {
    if (!username || !password || !location) {
      console.log("empty create form");
    } else {
      console.log("okay");
      fetch(`${PORT}/createuser`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, assetowner, password, location }),
      })
        .then((res) => {
          if (res.ok) {
            toast.success("user created successfully");
            fetchdata()
            setisbuttondisabled(false)
            setusername("")
            setpassword("")
            setassetowner("")
            setlocation("")
            return res.json();
          } else {
            toast.warn("user already exists");
            setisbuttondisabled(false)
            return res.json();
          }
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          toast.warn("OOPS! something went wrong try again")
        });
    }
  };

  const createuserform = () => {
    return (
      <div className="m-5">
      <form>
        <label>Username:</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">
            Username:
          </span>
          <input
          value={username}
            onChange={(event) => setusername(event.target.value)}
            type="text"
            class="form-control"
            placeholder="Enter User name"
            aria-label="username"
            aria-describedby="basic-addon1"
          />
        </div>
        <label>Asset Owner </label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">
            Asset Owner:
          </span>
          <input
          value={assetowner}
            onChange={(event) => setassetowner(event.target.value)}
            type="text"
            class="form-control"
            placeholder="Enter partner name"
            aria-label="partner name"
            aria-describedby="basic-addon1"
          />
        </div>
        <label>Password:</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">
            Password:
          </span>
          <input
          value={password}
            onChange={(event) => {
              setpassword(event.target.value);
              console.log(password);
            }}
            type="text"
            class="form-control"
            placeholder="Enter password"
            aria-label="password"
            aria-describedby="basic-addon1"
          />
        </div>
        <label>Branch Location:</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">
            Branch Location:
          </span>
          <input
          value={location}
            onChange={(event) => {
              setlocation(event.target.value);
              console.log(location);
            }}
            type="text"
            class="form-control"
            placeholder="Enter location of branch"
            aria-label="location"
            aria-describedby="basic-addon1"
          />
        </div>
        <button
        disabled={isbuttondisabled}
          onClick={(event) => {
            formvalidation();
            event.preventDefault();
            setisbuttondisabled(true)
    
          }}
          className="btn btn-success m-2"
        >
          Create login
        </button>
        <button
          onClick={() => {
            setaddlocationformisopen(true);
          }}
          className="btn btn-success m-2"
        >
          Add location
        </button>
      </form>
      </div>
    );
  };

  // const pop = () => {
  //   let pop1 = userdata.map((item) => {
  //     item = item._id === selectedbranchaddnewlocation;
  //     console.log(item);
  //   });
  //   console.log(pop1);
  // };
  // pop();
  const addlocation = () => {
    return fetch(`${PORT}/addnewlocation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        branchname: selectedbranchaddnewlocation,
        location: addnewlocation,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          toast.warn(`${addnewlocation} location already exists`);
          setisaddnewlocationbtndisabled(false)
        } else {
          return res.json(), toast.success(`${addnewlocation} new location added`),
          setisaddnewlocationbtndisabled(false)
        }
      })
      .then((data) => {
        return console.log(data), fetchdata();
      });
  };

  const pop = () => {
    let pop = userdata.filter((item, i) => {
      console.log(item._id === selectedbranchaddnewlocation);
      if (item._id === selectedbranchaddnewlocation) {
        console.log(item);
        return item;
      }
    });
    pop = pop.map((item, index) => {
      console.log(item.location);
      return item.location.map((item) => {
        console.log(item);
        return item.split("/n").map((item) => {
          return (
            <li
              onClick={(event) => {
                console.log(event.target.innerText);
              }}
            >
              {item}
            </li>
          );
        });
      });
    });
    return pop;
    console.log();
    // return <li>{pop}</li>;
  };

  const addlocationvalidation = () => {
    if (!addnewlocation || !selectedbranchaddnewlocation) {
      alert("enter location name to proceed");
    } else {
      addlocation();
    }
  };

  const addlocationform = () => {
    const locationinput = () => {
      return (
        <div className="m-5">
          <label>Enter location:</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              Location:
            </span>
            <input
            value={addnewlocation}
              onChange={(event) => {
                setaddnewlocation(event.target.value);
              }}
              type="text"
              class="form-control"
              placeholder="Enter location to add"
              aria-label="location"
              aria-describedby="basic-addon1"
            />
          </div>
          <button disabled={isaddnewlocationbtndisabled} onClick={()=>{
            return(
              addlocationvalidation(),setaddnewlocation(""),setisaddnewlocationbtndisabled(true)
            )}} className="btn btn-success">
            Submit
          </button>
          <div className="d-flex">
            <ul>{pop()}</ul>
          </div>
        </div>
      );
    };
    return (
      <div className="m-2">
        <Dropdown>
          <Dropdown.Toggle >select branch to add location</Dropdown.Toggle>
            <DropdownMenu style={{maxHeight:300,overflowY:'scroll'}}>
            {userdata.map((item) => {
              return (
                <DropdownItem
                  key={item._id}
                  eventKey={item._id}
                  onSelect={(value) => {
                    console.log(value);
                    setselectedbranchaddnewlocation(value);
                  }}
                >
                  {item.username}
                </DropdownItem>
              );
            })}
          {/* </DropdownButton> */}
          </DropdownMenu>
        </Dropdown>
        {userdata.filter((item) => {
          if (item._id === location) {
            console.log(item.username);
          }
        })}
        {selectedbranchaddnewlocation ? locationinput() : null}
      </div>
    );
  };

  return (
    <div className="">
      <h2>CREATE NEW USER</h2>
      <ToastContainer />
      {/* <img src={loadinggif} className="loadinggif" alt="" /> */}
      {/* {showform ? removebtnfn() : showbtnfn()} */}
      {/* {showform ? createuserform() : null} */}
      {/* {addlocationformisopen ? addlocationform() : createuserform()} */}
      {!addlocationformisopen ? createuserform() : addlocationform()}
    </div>
  );
};

export default Createuser;
